import { h } from 'preact';
import { memo } from 'preact/compat';
import style from './style.css';

type LinkPillProps = {
    link: string;
    icon: JSX.Element;
}

export const LinkPill = memo(function ({ link, icon }: LinkPillProps) {
    let url = link;
    //Try to process as URL
    try {
        url = new URL(link).hostname.replace('www.', '');
    } catch (e) { }
    return (
        <div
            class={`${style.linkPill} label-large`}
            onClick={() => window.open(link)}>
            <div class={style.link}>
                {url}
            </div>
            {icon}
        </div>
    );
});