/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from "preact";
import { memo, useEffect, useState } from "preact/compat";
import style from './style.css';
import { useProjects } from "../../context/project-context";
import { useParams } from "../../context/query-param-context";
import { Space } from "../space/space";

export const TagFilter = memo(function () {
    const { projects, filteredProjects } = useProjects();
    const { params, removeQuery } = useParams();
    const [hidden, setHidden] = useState(0);

    useEffect(() => {
        if (params.tag) {
            setHidden(projects.length - filteredProjects.length);
        }
    }, [params, projects, filteredProjects]);

    if (!params.tag) return <></>;
    return (
        <>
            <Space size={"small"} />
            <div class={`${style.tagFilter} label-large`} onClick={removeQuery}>
                {`${hidden} projects hidden by filter: #${params.tag}`}
                <div class={style.removeFilter}><i class="fa-solid fa-xmark" /></div>
            </div>
        </>
    )
}); 