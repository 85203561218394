import { createContext, h } from 'preact';
import { route } from 'preact-router';
import { useContext, useEffect, useState } from "preact/hooks";

type QueryType = {
    /**
     * Programmatically sets teh query tags
     * @param params 
     * @returns void
     */
    setQuery: (params: Params) => void,

    /**
     * 
     * @returns void
     */
    removeQuery: () => void,

    /**
     * possible url params
     */
    params: Params
};

type Params = {
    /**
     * The tag value defined in the URL params
     * 
     * `/?tag=<value>
     * 
     * Value can be undefined
     */
    tag?: string,
}

export const QueryParamContext = createContext<QueryType>({
    params: {},
    setQuery: () => { },
    removeQuery: () => { }
});

export function useParams() {
    return useContext(QueryParamContext);
}

export function QueryParamContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const [params, setParams] = useState<Params>({});

    function getQuery(query: URLSearchParams) {
        const tag = query.get('tag');
        if (tag) {
            setParams({ tag });
        }
    }

    useEffect(() => {
        getQuery(new URLSearchParams(window.location.search));
    }, [])

    function handleSetQuery(params: Params) {
        //Add params to route
        route(`?tag=${params.tag}`, true);
        setParams(params);
    }

    function handleRemoveQuery() {
        route('/');
        setParams({});
    }

    return (
        <QueryParamContext.Provider
            value={{
                params,
                setQuery: handleSetQuery,
                removeQuery: handleRemoveQuery
            }}
        >
            {children}
        </QueryParamContext.Provider>
    )
}