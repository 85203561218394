import { useEffect, useState } from 'preact/hooks';
interface UseMarkdownResponse {
    markdown: string;
    loading: boolean;
    error: any;
}

export const useMarkdown = (source: string): UseMarkdownResponse => {
    const [markdown, setMarkdown] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(source);
                const text = await response.text();
                setMarkdown(text);
                setLoading(false);
            } catch (error: any) {
                setError(error);
            }
        };
        fetchData();

    }, [source]);
    return { markdown, loading, error };
};