/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from "preact";
import { useState, useRef, useEffect } from 'preact/hooks';
import style from './style.css';
import ThreeJSModel from "./threejs-model";
import { NavLinks } from "../links/nav-links";
import { Skills } from "./skills";
import { Space } from "../space/space";
import { Profile } from "../../types/types";
import { memo } from "preact/compat";
import { useBackgroundPosition } from "../../hooks/";
import { TagMap } from "../tag-map/tag-map";
import { useScrollTo } from "../../context/scroll-to-context";
import { ContentLayout } from "../layouts/content-layout/content-layout";

//backdrop-filter: blur(${delta * 25}px);

type FrontPageProps = {
    profile: Profile;
}

export const FrontPage = memo(function ({ profile }: FrontPageProps) {
    const { scrollTo } = useScrollTo();
    const infoRef = useRef<any>();

    const [scroll, setScroll] = useState(0);
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const scrollFraction: number = (scroll / windowSize.height) * 2;
    const delta: number = ((scrollFraction - 0.5) * 2) / 1.5;

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowSize({ width, height });
        setScroll(window.pageYOffset);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        })
    }, []);

    function handleScroll() {
        setScroll(window.pageYOffset);
    }

    function handleResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowSize({ width, height });
    }

    const backPos = useBackgroundPosition(0, -100, -200, -700);

    return (
        <>
            <div class={`${style.frontPage}`} style={`opacity: ${0.5 - delta}; visibility: ${(0.5 - delta) > 0 ? 'visible' : 'hidden'};`}>
                <div class={style.fixedContent}>
                    <ContentLayout>
                        <div class={`${style.container} `}>
                            <div class={style.dummy} />
                            <div class={`${style.info} ${style.visible}`} ref={infoRef}>
                                <div
                                    class={`${style.header} display-large`}
                                    style={{ backgroundPosition: `${backPos.x} ${backPos.y}` }}
                                >
                                    <div class={style.name}>
                                        <div>{profile.name.first}</div>
                                        <div>{profile.name.last}</div>
                                    </div>
                                    <div class={`${style.title} headline-fancy`}>
                                        <div>{`Software`}</div>
                                        <div>{`Engineer`}</div>
                                    </div>
                                </div>
                                <Space size={"medium"} />
                                <div class={`${style.about} body-large`}>
                                    {profile.about}
                                    <a class={style.moreInfo} onClick={() => scrollTo('about', true)}>{` >>`}</a>
                                </div>
                                <Space size={"medium"} />
                                <TagMap />
                            </div>
                        </div>
                    </ContentLayout>
                </div>
                <ThreeJSModel />
                <div class={style.flexRow}>
                    <div class={style.flexColumn}>
                        <div
                            class={`${style.projectsButton}`}
                            onClick={() => scrollTo('projects', true)}>
                            <i class={"fa-solid fa-caret-down fa-bounce"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});
