import { h } from "preact";
import { Link, Router, route } from 'preact-router';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useProjects } from "../../context/project-context";
import { Space } from "../space/space";
import { ProjectDetailView } from "./project-detail-view";
import { ProjectItem } from "./project-item";
import style from './style.css';
import { TagFilter } from "../tag-map/tag-filter";

export const Projects = memo(function () {
    const { filteredProjects } = useProjects();
    const bottomBufferRef: any = useRef();
    const initalLoadCount = 6;
    const loadCount = 3;

    const [loaded, setLoaded] = useState(initalLoadCount);

    async function handleLoad() {
        await timeout(100);
        if (loaded === filteredProjects.length) return;
        if (loaded + loadCount > filteredProjects.length)
            setLoaded(filteredProjects.length);
        else {
            const newL = loaded + loadCount;
            setLoaded(newL);
        }
    };

    function handleInview(entries: any, observer: any) {
        entries.forEach((entry: any) => {
            if (entry.intersectionRatio) {
                handleLoad();
                observer.unobserve(bottomBufferRef.current);
            }
        })
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleInview);
        if (bottomBufferRef.current)
            observer.observe(bottomBufferRef.current);
    }, [loaded]);

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    };

    return (
        <div class={style.projects}>
            <div class={style.container}>
                <div class={`${style.header} display-large`}>
                    <span>{`Projects.`}</span>
                </div>
                <TagFilter />
                <Space size={"large"} />
                <div class={style.projectLayout}>
                    <div class={style.grid}>
                        {
                            filteredProjects.map(project =>
                                <Link href={project.enabled ? `/${escape(project.title)}` : ''} key={project.title}>
                                    <ProjectItem
                                        enabled={project.enabled}
                                        project={project}
                                    />
                                </Link>
                            )
                        }
                    </div>
                    <Router>
                        {
                            filteredProjects.map(project =>
                                <div
                                    key={project.title}
                                    path={`/${escape(project.title)}`}>
                                    <ProjectDetailView
                                        project={project}
                                        onClose={() => {
                                            route("/");
                                        }}
                                    />
                                </div>)
                        }
                        <div default />
                    </Router>
                </div>
            </div>
        </div>
    )
});
