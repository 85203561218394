import { h } from 'preact';
import { memo } from 'preact/compat';
import style from './style.css';

type LogoProps = {
    color: string;
    size: number;
    onClick?: () => void;
}

export const Logo = memo(function ({ color, size, onClick }: LogoProps) {
    return (
        <div class={style.logo} style={`width: ${size}px; height: ${size}px;`} onClick={onClick}>
            <svg style={`width: ${size}px; height: ${size}px;`}
                width="566.93px" height="566.93px" viewBox="0 0 566.93 566.93" enable-background="new 0 0 566.93 566.93">
                <g id="Layer_1">
                    <polygon fill={color} points="283.465,35.376 70.866,110.091 70.866,151.339 186.829,151.339 186.829,423.506 148.175,423.506 148.175,191.58 
                        70.866,191.58 70.866,423.506 70.866,464.33 70.872,464.33 264.137,531.554 264.137,423.506 264.137,151.339 264.137,151.339 
                        264.137,112.684 302.792,112.684 302.792,151.339 302.792,151.339 302.792,423.506 302.792,531.554 380.1,504.665 380.1,423.506 
                        380.101,423.506 380.101,151.339 418.755,151.339 418.755,423.506 418.755,491.219 496.058,464.33 496.063,464.33 496.063,423.506 
                        496.063,151.339 496.063,151.339 496.063,110.091"
                    />
                </g>
            </svg>
        </div>
    );
});

export const LegSharkLogo = memo(function ({ color, size, onClick }: LogoProps) {
    return (
        <div class={style.legSharkLogo} style={`width: ${size}px; height: ${size}px;`} onClick={onClick}>
            <svg style={`width: ${size}px; height: ${size}px;`}
                width="566.93px" height="566.93px" viewBox="0 0 566.93 566.93">
                <g transform='scale(1.75) translate(-50,-100)'>
                    <g>
                        <path fill={color} d="m294.24,229.39c-13.56-31.83-43.69-61.99-77.01-70.15-.05-.14-.11-.28-.19-.41-7.27-12.8-1.64-25.01,2.58-31.38,1.26-1.9-.16-4.5-2.37-4.32-33.73,2.7-46.93,26.21-50.9,35.65-46,10.37-74.79,43.77-83.61,96.5-1.52,9.1,33.93,24.86,41.23,30.2,3-6.97,7.04-16.33,7.04-16.33,2.07-5.05,8.99-5.74,11.96-1.26,0,0,10.67,15.48,17.85,25.89l11.75-28.65c1.76-4.98,8.37-5.93,11.35-1.66,0,0,12.36,17.23,19.22,26.78l21.32-30.85c2.74-5.53,10.58-1.7,8.21,4.08,0,0-24.44,38.19-24.44,38.19-.46,1.07-1.26,1.83-2.19,2.29-1.75.85-4,.58-5.39-1.04,0,0-.19-.22-.19-.22,0,0-2.09-2.35-5.08-5.71-4.21-4.72-10.2-11.45-14.7-16.5l-5.58,12.7-7.19,16.37c-1.08,2.63-3.69,3.93-6.19,3.76-1.69-.12-3.34-.91-4.44-2.44-.02,0-11.01-14.74-18.56-24.87l-6.02,12.76c-1.54,3.27-5.21,4.71-8.4,3.4l-.68-.28s-22.97-13.42-41.61-21.15c9.1,30.22,39.87,50.09,74.81,54.98-2.39,7.43-5.64,14.44-10.07,19.77-.76.91-1.9.94-3.05.76-13.16-2.05-32.74-5.82-33.15,3.25-.57,12.65,34.61,26.9,41.98,30.91,1.02.56,2.26.38,3.1-.45,5.05-4.99,22.57-23.46,37.05-52.26,7.52-.11,14.88-.49,21.97-1.22-1.06,10.42-1.7,20.08-1.46,27.19.05,1.57-1.2,2.44-2.59,3.01-13.18,5.43-32.32,11.71-27.86,19.67,6.51,11.6,55.33,6.56,55.33,6.56,1.52-.01,2.75-1.26,2.8-2.84.3-8.46,1.56-34.57,6.2-60.05,20.72-6.66,36.89-18.13,45.38-36.2l1.8-64.43Zm-118.48.08c-.52,9.24-7.94,16.78-17.15,17.42-11.16.78-20.43-8.39-19.8-19.59.52-9.24,7.94-16.78,17.15-17.42,11.16-.78,20.43,8.39,19.8,19.59Z" />
                        <path fill={color} d="m340.53,213.19c.87-1.57-.52-3.44-2.47-3.35-32.73,1.5-60.47,23.41-65.38,54.1-4.88,30.52,14.4,59.82,44.72,71.59,1.84.71,3.76-.64,3.41-2.42,0,0-17.71-35.46-12.1-63.89,5.62-28.44,31.82-56.04,31.82-56.04Z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}) 
