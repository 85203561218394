import { h } from 'preact';
import { createContext } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { Project } from "../types/types";
import { useParams } from './query-param-context';

export type Projects = {
    /**
     * Projects in their raw default (directly from the JSON file)
     */
    projects: Project[],

    /**
     * Projects that have been sorted and filtered by the
     * quary param.
     */
    filteredProjects: Project[]
};

export const ProjectContext = createContext<Projects>(
    {
        projects: [],
        filteredProjects: []
    }
);

export function useProjects() {
    return useContext(ProjectContext);
}

export function ProjectContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const projects: Project[] = require("../data/projects.json")
    const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
    const { params } = useParams();
    useEffect(() => {
        //Sort projects by rating
        const s: Project[] = projects.sort(
            (a, b) => {
                return Number(b.rating) - Number(a.rating)
            },
        );
        //Filter by tag if exists
        if (params.tag) {
            const f: Project[] = s.filter(project => {
                if (project.tags && project.tags.length > 0) {
                    if (params.tag)
                        return project.tags.includes(params.tag.toLocaleLowerCase())
                }
            });
            setFilteredProjects(f);
        }
        else
            setFilteredProjects(s);
    }, [projects, params]);

    return <ProjectContext.Provider value={{ projects, filteredProjects }}>
        {children}
    </ProjectContext.Provider>
}