import { h } from "preact";
import { memo } from "preact/compat";
import style from './style.css';

type SpaceProps = {
    enabled?: any;
    size: Size;
}

type Size =
    'tiny' | 'small' | 'medium' | 'large' | 'huge' | 'enormous';

export const Space = memo(function ({ enabled, size }: SpaceProps) {
    let e = true;
    if (enabled !== undefined)
        e = enabled;
    return e ? <div class={`${style.space} ${style[size]}`} /> : null;
})