/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-danger */
/** @jsx h */
/** @jsxFrag Fragment */
import { Fragment, RefObject, h } from "preact";
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useVisible } from "../../hooks";
import { Citation, Project, Image, Table } from "../../types/types";
import { ImageViewer } from "../image-viewer/image-viewer";
import { Logo } from "../logo/logos";
import { Markdown } from "../markdown/markdown";
import Skeleton from '../skeleton/skeleton';
import { Space } from "../space/space";
import { LinkPill } from "./link-pill";
import { ProjectTable } from "./project-table";
import style from './style.css';
import { ContentLayout } from "../layouts/content-layout/content-layout";

type ProjectDetailViewProps = {
    onClose: () => void;
    project: Project;
}

export const ProjectDetailView = memo(function ({ onClose, project }: ProjectDetailViewProps) {
    const detailRef = useRef<any>();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return (() => {
            document.body.style.overflow = 'visible';
        })
    }, []);

    useVisible(() => {
        detailRef.current.classList.add(style.visible);
    }, () => {
        detailRef.current.classList.remove(style.visible);
    });

    const [headerOffset, setHeaderOffset] = useState(0);

    function handleResize() {
        const offset = (window.innerWidth - 1218) / 2;
        if (offset > 0)
            setHeaderOffset(offset);
        else
            setHeaderOffset(0);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    }, [])

    return (
        <>
            <div class={`${style.detail}`} ref={detailRef}>
                <ContentLayout>
                    <div class={`${style.content}`}>
                        <div
                            class={`${style.header}`}
                            style={{ backgroundPosition: `${headerOffset}px -600px` }}
                        >
                            <Space size={"tiny"} />
                            <div class={style.topBar}>
                                <Logo size={50} color={`#FFFFFF`} />
                                <i class="fa-solid fa-xmark" onClick={onClose} />
                            </div>
                            <Space size={"small"} />
                            <Tags tags={project.tags} />
                            <Space size={"tiny"} />
                            <div class={`${style.title} display-large`}>
                                {project.title}
                            </div>
                            <Space size={"medium"} />
                            <div class={style.split}>
                                <div
                                    class={`${style.caption}  title-large`}
                                    dangerouslySetInnerHTML={{ __html: `${project.caption}` }}
                                />
                                <div class={`${style.links} headline-small`}>
                                    <Link link={project.github} icon={<i class="fa-brands fa-github" />} />
                                    <Link link={project.website} icon={<i class="fa-solid fa-link" />} />
                                </div>
                            </div>
                        </div>
                        <div class={style.splitView}>
                            <StickyTable table={project.table} />
                            <div class={style.right}>
                                <Space size={"large"} />
                                <Videos videos={project.videos} />
                                <Markdown source={project.markdown} />
                                <Citations citations={project.citations} />
                                <Images images={project.images} />
                                <Affiliations affiliations={project.affiliations} />
                                <Space size={"large"} />
                            </div>
                        </div>
                    </div>
                </ContentLayout>
            </div>
        </>
    )
});

const Tags = memo(function ({ tags }: { tags: string[] }) {
    return (
        <>
            <div class={`${style.tags} label-large`}>
                {
                    tags.map((tag, index) => {
                        return <div key={index} className='tag'>
                            {`#${tag}`}
                        </div>
                    })
                }
            </div>
        </>
    )
});

const Link = memo(function ({ link, icon }: { link: string, icon: JSX.Element }) {
    if (!link || link === '') return <></>;
    return (
        <>
            <LinkPill link={link} icon={icon} />
        </>
    )
})

type StickTableProps = {
    table: Table | undefined;
};

const StickyTable = memo(function ({ table }: StickTableProps) {
    if (!table) return <></>;
    return (
        <>
            <div class={style.left}>
                <div class={`${style.sticky}`}>
                    {/* <Space size={"large"} /> */}
                    <ProjectTable table={table} />
                </div>
            </div>
        </>
    )
})

const Citations = memo(function ({ citations }: { citations: Citation[] }) {
    if (!citations || citations.length === 0) return <></>;
    return (
        <>
            <div class={style.citations}>
                <div class={style.citeList}>
                    {
                        citations.map((cite: Citation) =>
                            <div
                                key={cite.cite}
                                class={style.cite}
                            >
                                <div class={style.bar} />
                                <div class={`${style.copyText} label-large`}>
                                    {cite.cite}
                                </div>
                            </div>)
                    }
                </div>
            </div>
            <Space size="large" />
        </>
    )
})

const Videos = memo(function ({ videos }: { videos: string[] }) {
    const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
    if (!videos || videos.length === 0) return <></>;
    return (
        <>
            {
                videos.map((url, index) =>
                    <>
                        <div key={url} class={style.video} style={{ borderRadius: 'var(--corner-radius)', overflow: 'hidden' }}>
                            <div class={style.videoLoader} style={{ display: 'block' }}>
                                <Skeleton width='100%' height='100%' />
                            </div>
                            <iframe
                                src={url}
                                frameBorder={"0"}
                                allowFullScreen={true}
                                onLoad={() => setVideoLoaded(true)}
                                style={{ height: videoLoaded ? '100%' : 0 }}
                            />
                        </div>
                        {index !== videos.length - 1 ? <Space size={"large"} /> : <></>}
                    </>
                )
            }
            <Space size="large" />
        </>
    )
})

const Images = memo(function ({ images }: { images: Image[] }) {
    if (!images || images.length === 0) return <></>;
    return (
        <>
            <div class='display-medium'>
                {`Gallery`}
            </div>
            <Space size={"large"} />
            <ImageViewer
                images={images}
            />
            <Space size="large" />
        </>
    );
})

const Affiliations = memo(function ({ affiliations }: { affiliations: string[] }) {
    if (!affiliations || affiliations.length === 0) return <></>;
    return (
        <>
            <div class={style.affiliations}>
                <div class={`${style.title} title-large`}>
                    {`In affiliation with`}
                </div>
                <Space size={"medium"} />
                <div class={style.logo}>
                    {affiliations.map((x: any) => <img key={x} src={`assets/${x}`} />)}
                </div>
            </div>
        </>
    )
});
