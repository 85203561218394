/* eslint-disable react/no-danger */
import { h } from 'preact';
import { memo } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { Image } from '../../types/types';
import { LazyImage } from '../lazy-img/lazy-image';
import { Space } from '../space/space';
import { ModalImageViewer } from './modal-image-viewer';
import style from './style.css';

type ImageViewProps = {
    images: Image[];
}

export const ImageViewer = memo(function ({ images }: ImageViewProps) {
    const [selected, setSelected] = useState(images[0]);
    const [showModal, setShowModal] = useState(false);
    
    function handleImageClicked(image: Image) {
        setSelected(image)
        setShowModal(true);
    };

    useEffect(() => {
        setSelected(images[0]);
    }, [images]);

    return (
        <div class={style.imageViewer}>
            <Thumbs images={images} onImageClick={handleImageClicked} />
            <ModalImageViewer
                visible={showModal}
                setVisible={setShowModal}
                image={selected}
            />
        </div>
    );
});

export const Thumbs = memo(function ({ images, onImageClick }: { images: Image[], onImageClick: (image: Image) => void }) {
    return (<div class={style.thumbnails}>
        {
            images.map((image: Image) => {
                return <div key={image.source} class={style.thumbnail}>
                    <LazyImage
                        src={`/assets/${image.source}`}
                        onClick={() => onImageClick(image)} />
                </div>
            })
        }
    </div>)
})

