/* eslint-disable react/no-danger */
import { h } from 'preact';
import { memo } from 'preact/compat';
import { Table } from '../../types/types';
import style from './style.css';

type ProjectTableProps = {
    table: Table;
}

export const ProjectTable = memo(function ({ table }: ProjectTableProps) {
    const titleClass = `${style.title} title-large`;
    return (
        <div class={`${style.projectTable} body-large`}>
            {table.role ? <div>
                <div class={titleClass}>
                    Role
                </div>
                <div dangerouslySetInnerHTML={{ __html: table.role }} />
            </div> : null}
            {table.team ? <div>
                <div class={titleClass}>
                    Team
                </div>
                {table.team.map(x => <li
                    key={x}
                    dangerouslySetInnerHTML={{ __html: x }}
                />)}
            </div> : null}
            {table.time ? <div>
                <div class={titleClass}>
                    Time
                </div>
                <div dangerouslySetInnerHTML={{ __html: table.time }} />
            </div> : null}
            {table.tools ? <div>
                <div class={titleClass}>
                    Tools & Software
                </div>
                {table.tools.map(x =>
                    <li
                        class={""}
                        key={x}
                        dangerouslySetInnerHTML={{ __html: x }}
                    />)}
            </div> : null}
            {table.outcomes ? <div>
                <div class={titleClass}>
                    Outcomes
                </div>
                {table.outcomes.map(x =>
                    <li
                        class={""}
                        key={x}
                        dangerouslySetInnerHTML={{ __html: x }}
                    />)}
            </div> : null}
        </div>
    );
});

