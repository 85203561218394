/* eslint-disable @typescript-eslint/no-var-requires */
/** @jsx h */
/** @jsxFrag Fragment */
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { ProjectContextProvider } from '../context/project-context';
import { Profile } from '../types/types';
import { About } from './about/about';
import { BackToTop } from './back-to-top/back-to-top';
import { Footer } from './footer/footer';
import { FrontPage } from './front-page/front-page';
import { Projects } from './projects/projects';
import { Space } from './space/space';
import { useRouter } from 'preact-router';
import { QueryParamContextProvider } from '../context/query-param-context';
import { ScrollToContext, ScrollToContextProvider } from '../context/scroll-to-context';
import { NavBar } from './nav-bar/nav-bar';
import { ContentLayout } from './layouts/content-layout/content-layout';

const profile: Profile = require("../data/profile.json");

export default function App() {
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [isDetailRoute, setIsDetailRoute] = useState(false);

    const projectRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.addEventListener("scroll", HandleShowBackToTop);
        return (() => {
            window.removeEventListener("scroll", HandleShowBackToTop);
        })
    }, [])

    function HandleShowBackToTop() {
        setShowBackToTop(!isDetailRoute && window.scrollY > window.innerHeight / 2 &&
            (window.innerHeight + window.scrollY) < document.body.offsetHeight);
    }

    useEffect(() => {
        HandleShowBackToTop();
    }, [isDetailRoute]);

    function handleScrollToProjects(smooth?: boolean) {
        projectRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
    }

    function handleScrollToAbout(smooth?: boolean) {
        aboutRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
    }

    return (
        <div id="app">
            <QueryParamContextProvider>
                <ProjectContextProvider>
                    <ScrollToContextProvider scrollables={[
                        {
                            name: 'projects',
                            onScroll: handleScrollToProjects
                        },
                        {
                            name: 'about',
                            onScroll: handleScrollToAbout
                        }
                    ]}
                    >
                        <NavBar />
                        <FrontPage
                            profile={profile}
                        />
                        <ContentLayout>
                            <div ref={projectRef}>
                                <div style={{ height: '48px' }} />
                                <Projects />
                            </div>
                            <Space size='huge' />
                            <div ref={aboutRef}>
                                <div style={{ height: '48px' }} />
                                <About />
                            </div>
                            <BackToTop
                                visible={showBackToTop}
                            />
                            <Space size='huge' />
                            <Footer />
                        </ContentLayout>
                    </ScrollToContextProvider>
                </ProjectContextProvider>
            </QueryParamContextProvider>
        </div>
    )
}

