import { h } from 'preact';
import { memo } from 'preact/compat';
import style from './style.css';
import { Link } from 'preact-router';

export const FALink = memo(function (props: any) {
    return (
        <Link href={props.source}>
            <div class={style.contactLink}>
                <i class={props.icon} />
            </div>
        </Link>
    );
});
