import { h } from 'preact';
import { memo, useEffect, useState } from 'preact/compat';
import { useProjects } from '../../context/project-context';
import style from './style.css';
import { mapValue } from '../../utils/map-value';
import { useParams } from '../../context/query-param-context';
import { useScrollTo } from '../../context/scroll-to-context';

interface TagWeight {
    tag: string;
    occurances: number;
}

export const TagMap = memo(function () {
    const { projects } = useProjects();
    const [tagWeights, setTagWeights] = useState<TagWeight[]>([]);
    const [minMax, setMinMax] = useState<{ min: number, max: number }>({ min: 1, max: 1 })

    useEffect(() => {
        const weights: TagWeight[] = [];

        //Calcualte weights
        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.tags && project.tags.length > 0) {
                for (let j = 0; j < project.tags.length; j++) {
                    const t = weights.find(x => x.tag === project.tags[j]);
                    if (t)
                        t.occurances++;
                    else
                        weights.push({ tag: project.tags[j], occurances: 1 });
                }
            }
        }

        //calculate min max
        let max = -Infinity;
        let min = Infinity;

        weights.forEach(x => {
            if (x.occurances > max)
                max = x.occurances;
            if (x.occurances < min)
                min = x.occurances;
        });

        //Set weights and min max
        setTagWeights(weights.sort((a, b) => { return Number(b.occurances) - Number(a.occurances) },));
        setMinMax({ min, max });

    }, [projects])

    return (
        <div class={style.tagMap}>
            {
                tagWeights.map((tagWeight) => {
                    return <Tag key={tagWeight.tag} tagWeight={tagWeight} minMax={minMax} />
                })
            }
        </div>
    );
});

export const Tag = memo(({ tagWeight, minMax }: { tagWeight: TagWeight, minMax: { min: number, max: number } }) => {
    const { params, setQuery, removeQuery } = useParams();
    const { scrollTo } = useScrollTo();
    const [isSelected, setIsSelected] = useState<boolean>(false);
    useEffect(() => {
        if (params.tag) {
            setIsSelected(tagWeight.tag === params.tag);
        }
        else {
            setIsSelected(false);
        }
    }, [params])
    function setTagParamInQuery(value: string) {
        if (value === params.tag) {
            //If param is the selected, unSelected
            removeQuery();
            setIsSelected(false);
            return;
        }
        //route to projects
        setQuery({ tag: value });
        scrollTo("projects", true);
    }

    //TODO: Make the close button in project detail view go back in history

    return (
        <div
            class={`${style.tag} ${isSelected ? style.selected : ''} label-large`}
            onClick={() => setTagParamInQuery(tagWeight.tag)}
            style={{
                fontSize: `${mapValue(tagWeight.occurances, minMax.min, minMax.max, 12, 14)}px`,
                opacity: isSelected ? 1 : mapValue(tagWeight.occurances, minMax.min, minMax.max, 0.5, 1)
            }}
        >
            #{tagWeight.tag}
        </div>
    )
});

