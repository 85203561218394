/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from 'preact';
import { memo } from 'preact/compat';
import style from './style.css';

type SkeletonProps = {
    width?: string | number,
    height?: string | number,
    count?: number,
    borderRadius?: string | number
}


const Skeleton = memo(function ({
    width = '100%',
    height, count = 1,
    borderRadius
}: SkeletonProps) {
    const parts = [];
    for (let i = 0; i < count; i++) {
        parts.push(i);
    }
    return (
        <>
            {
                parts.map(index =>
                    <span
                        style={{
                            width: width,
                            height: height,
                            borderRadius: borderRadius
                        }}
                        key={index}
                        class={style.skeleton}
                    >
                        &zwnj;
                    </span>
                )
            }
        </>
    )
})

export default Skeleton;