/* eslint-disable @typescript-eslint/ban-ts-comment */
import { h, createRef } from 'preact';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import style from './style.css';
import { createNoise2D, createNoise3D } from 'simplex-noise';
import { MeshStandardMaterial } from 'three';
import { PureComponent } from 'preact/compat';
import { gsap, Elastic } from 'gsap'

class ResourceTracker {
    resources: (THREE.BufferGeometry | THREE.Material | THREE.Texture)[];
    constructor() {
        this.resources = [];
    }
    trackGeometry(geo: THREE.BufferGeometry): THREE.BufferGeometry {
        this.track(geo);
        return geo;
    }
    trackMaterial(mat: THREE.Material): THREE.Material {
        this.track(mat);
        return mat;
    }
    trackTexture(tex: THREE.Texture): THREE.Texture {
        this.track(tex);
        return tex;
    }
    private track(resource: THREE.BufferGeometry | THREE.Material | THREE.Texture) {
        this.resources.push(resource);
    }
    /**
     * Disposes of the currently tacked items
     */
    dispose() {
        this.resources.forEach(resource => {
            resource.dispose();
        });
        //clear
        this.resources = [];
    }
}

export default class ThreeJSModel extends PureComponent {
    constructor(props: any) {
        super(props);
        this.resourceTracker = new ResourceTracker();
        this.trackGeometry = this.resourceTracker.trackGeometry.bind(this.resourceTracker);
        this.trackMaterial = this.resourceTracker.trackMaterial.bind(this.resourceTracker);
        this.trackTexture = this.resourceTracker.trackTexture.bind(this.resourceTracker);
        this.dispose = this.resourceTracker.dispose.bind(this.resourceTracker);
    }

    state = {
        hasLoaded: false,
        loadProgress: 0
    }

    mount: any;
    animationRef = createRef();
    resourceTracker: ResourceTracker;
    trackGeometry;
    trackMaterial;
    trackTexture;
    dispose;

    componentDidMount() {

        interface Dot {
            origin: THREE.Vector3;
            point: THREE.Points;
        }

        const dots: Dot[] = [];
        let scene: THREE.Scene,
            camera: THREE.PerspectiveCamera,
            renderer: THREE.WebGLRenderer,
            wireModel: THREE.Group,
            solidModel: THREE.Group,
            wireMat: THREE.MeshStandardMaterial,
            solidMat: THREE.MeshStandardMaterial,
            cameraPivot: THREE.Object3D;
        //Lights
        let hemispshereLight, shadowLight, light2, light3;

        const mouse = new THREE.Vector2();
        const client = new THREE.Vector2();

        const modelScale = 6;

        const init = async () => {
            //Create scene
            scene = new THREE.Scene();
            camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);

            cameraPivot = new THREE.Object3D();
            scene.add(cameraPivot);


            //set camera parent to be the camera pivot
            camera.position.set(14, 0, 18);
            // camera.rotation.x = THREE.MathUtils.degToRad(-15);
            cameraPivot.add(camera);

            renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;

            const fog = new THREE.Fog(0xfafafa, 32, 42);
            scene.fog = fog;

            const shaderMaterial = new THREE.ShaderMaterial({
                uniforms: THREE.UniformsUtils.merge([
                    THREE.UniformsLib['fog'],
                    {
                        mouseX: { type: "f", value: 0 },
                        mouseY: { type: "f", value: 0 },
                        noise: { type: "f", value: 0 },
                        fogColor: { type: "c", value: fog.color },
                        fogNear: { type: "f", value: fog.near },
                        fogFar: { type: "f", value: fog.far },
                        time: { type: 'f', value: 0 },
                    },
                ]),
                vertexShader: /*glsl*/`
                    uniform float mouseX;
                    uniform float mouseY;
                    uniform float noise;
                    uniform float time;
                    varying vec4 vPos;

                    void main() {                        
                        // float y = position.y * 0.35;
                        float y = (position.y * cos(position.x - mouseX)) + (position.y * cos(position.z + mouseY));
                        vec4 vetexPos = vec4(position.x, y* 0.5, position.z, 1.0);
                        gl_Position = projectionMatrix * modelViewMatrix * vetexPos;
                        vPos = vetexPos;
                    }
                `,
                fragmentShader: /*glsl*/`

                    uniform vec3 fogColor;
                    uniform float fogNear;
                    uniform float fogFar;
                    uniform float mouseX;
                    uniform float mouseY;
                    uniform float noise;

                    varying vec4 vPos;

                    void main()	{
                        gl_FragColor = vec4(0.408, 0.667, 0.839,1);
                        float depth = gl_FragCoord.z / gl_FragCoord.w;
                        float fogFactor = smoothstep( fogNear, fogFar, depth );
                        gl_FragColor.rgb = mix( vec3(0.408, 0.667, 0.839), fogColor, fogFactor );
                    }
                `,
                fog: true,
                wireframe: true
            });

            //Dom Element (preact ref)
            this.mount.appendChild(renderer.domElement);

            //Create background dots
            const createDots = () => {
                const count = 200;
                const dotGeometry = this.trackGeometry(new THREE.BufferGeometry());
                dotGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));
                const dotMaterial = this.trackMaterial(new THREE.PointsMaterial({ size: 0.07, color: 0xd5d5d5 }));
                dotMaterial.transparent = true;
                const dotOffset = 7.5;
                for (let i = 0; i < count; i++) {
                    const dot = new THREE.Points(dotGeometry, dotMaterial);
                    scene.add(dot);
                    dot.position.set(
                        THREE.MathUtils.lerp(dotOffset, -dotOffset, THREE.MathUtils.randFloat(0, 1)),
                        THREE.MathUtils.lerp(dotOffset, -dotOffset, THREE.MathUtils.randFloat(0, 1)),
                        THREE.MathUtils.lerp(dotOffset, -dotOffset, THREE.MathUtils.randFloat(0, 1))
                    )
                    const origin = new THREE.Vector3();
                    dots.push(
                        {
                            origin: origin.copy(dot.position),
                            point: dot
                        }
                    );

                }
            }
            // createDots();

            //Add ground plane
            const createPlane = () => {
                const planeGeometry = this.trackGeometry(new THREE.PlaneGeometry(1000, 1000));
                const planeMaterial = this.trackMaterial(new THREE.ShadowMaterial());
                planeMaterial.opacity = 0.2;
                const plane = new THREE.Mesh(planeGeometry, planeMaterial);
                plane.receiveShadow = true;
                plane.rotation.x = -0.5 * Math.PI; // -90º
                plane.position.set(15, -2, 0);
                scene.add(plane);
            }
            // createPlane();

            const createLights = () => {
                hemispshereLight = new THREE.HemisphereLight(0xffffff, 0x000000, .5)

                shadowLight = new THREE.DirectionalLight(0xffffff, .1);
                shadowLight.position.set(0, 45, 35);
                shadowLight.castShadow = true;

                shadowLight.shadow.camera.left = -65;
                shadowLight.shadow.camera.right = 65;
                shadowLight.shadow.camera.top = 65;
                shadowLight.shadow.camera.bottom = -65;
                shadowLight.shadow.camera.near = 1;
                shadowLight.shadow.camera.far = 1000;

                shadowLight.shadow.mapSize.width = 4096;
                shadowLight.shadow.mapSize.height = 4096;

                light2 = new THREE.DirectionalLight(0xffffff, .25);
                light2.position.set(-60, 35, 35);

                light3 = new THREE.DirectionalLight(0xffffff, .15);
                light3.position.set(0, -5, 80);

                scene.add(hemispshereLight);
                scene.add(shadowLight);
                scene.add(light2);
                scene.add(light3);
            }
            // createLights();

            const createWireModel = async () => {
                const AddModel = async (): Promise<THREE.Group> => {
                    const loader = new GLTFLoader();
                    const gtlf = await loader.loadAsync('/assets/logo_model_wire.glb');
                    //track geometry
                    const rt = this.resourceTracker;
                    gtlf.scene.traverse((node: THREE.Object3D) => {
                        if (node instanceof THREE.Mesh) {
                            rt.trackGeometry(node.geometry);
                            rt.trackMaterial(node.material);
                        }
                    });
                    return gtlf.scene;
                }

                wireMat = this.trackMaterial(shaderMaterial) as MeshStandardMaterial;

                wireModel = await AddModel();
                wireModel.traverse((node: any) => {
                    if (node instanceof THREE.Mesh) {
                        node.castShadow = true;
                        node.receiveShadow = true;
                        node.material = wireMat;
                    }
                });
                const scale = new THREE.Vector3(modelScale, modelScale, modelScale);
                const pos = new THREE.Vector3(0, 0, -21);
                wireModel.scale.copy(scale);
                wireModel.position.copy(pos);
                scene.add(wireModel);
            };
            if (innerWidth >= 600)
                createWireModel();

            //Mouse smoothing
            const onMouseMove = (e: any) => {
                client.x = e.clientX;
                client.y = e.clientY;
                gsap.to(mouse, 1.5, {
                    x: (e.clientX / window.innerWidth) * 2 - 1,
                    y: -(e.clientY / window.innerHeight) * 2 + 1,
                    ease: Elastic.easeOut
                });
            };
            ['mousemove', 'touchmove'].forEach(event => {
                window.addEventListener(event, onMouseMove);
            });

            //On window resize, update renderer size and camera aspect
            window.addEventListener('resize', () => {
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(window.innerWidth, window.innerHeight);
                renderer.setPixelRatio(window.devicePixelRatio);

                if (window.innerWidth < 600) {
                    if (wireModel)
                        wireModel.visible = false;
                    if (solidModel)
                        solidModel.visible = false;
                }
                else if (wireModel)
                    wireModel.visible = true;
                else
                    createWireModel();

            }, false);


        };

        //Helpers
        let delta = 0;
        let lastTime = 0;
        const aninNoise = createNoise2D();
        const dotNoise = createNoise3D();

        const animate = (time: number) => {
            requestAnimationFrame(animate);
            delta = time - lastTime;
            lastTime = time;

            const scroll = window.pageYOffset / window.innerHeight;

            const res = aninNoise(
                (time * 0.003),
                (time * 0.003)
            );

            const res2 = aninNoise(
                (time * 0.0005),
                (time * 0.0005)
            );

            if (wireModel && wireModel.visible) {
                //@ts-ignore
                wireMat.uniforms.noise.value = res2;
                //@ts-ignore
                wireMat.uniforms.mouseX.value = mouse.x;
                //@ts-ignore
                wireMat.uniforms.mouseY.value = mouse.y;
                //@ts-ignore
                wireMat.uniforms.time.value = time;

                wireModel.rotation.y = 0.25 + mouse.x / 8;
                wireModel.rotation.x = -mouse.y / 8;
                // wireModel.position.y = 1 + res2 * 0.1;
            }


            camera.position.y = mouse.y * 0.2;

            //manipulate points
            if (dots && dots.length > 0) {
                for (let i = 0; i < dots.length; i++) {
                    const dot = dots[i];
                    const noise = dotNoise(
                        dot.origin.x * 0.05 + (time * 0.0001),
                        dot.origin.y * 0.05 + (time * -0.0001),
                        dot.origin.z * 0.05 + (time * 0.0001)
                    );
                    const dist = 0.2;
                    dot.point.position.lerpVectors(
                        new THREE.Vector3(
                            dot.origin.x - dist,
                            dot.origin.y - dist,
                            dot.origin.z - dist
                        ),
                        new THREE.Vector3(
                            dot.origin.x + dist,
                            dot.origin.y + dist,
                            dot.origin.z + dist
                        ),
                        noise
                    );
                    // dot.point.position.z = dot.origin.z + (scroll * 10)
                    dot.point.position.y /= 1 + (scroll)
                    // dot.point.position.z /= 1 + (scroll * 10)
                }
            }
            renderer.render(scene, camera);
        };

        init();
        this.animationRef.current = requestAnimationFrame(animate);

    }

    componentWillUnmount() {
        this.dispose();
        cancelAnimationFrame(this.animationRef.current);
    }

    render() {
        return (
            <div class={style.threeJSModel}>
                <div ref={ref => (this.mount = ref)} />
            </div>
        )
    }
}