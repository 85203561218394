/* eslint-disable react/no-danger */
/* eslint-disable no-duplicate-imports */
/** @jsx h */
/** @jsxFrag Fragment */
import { Fragment, h } from 'preact';
import { memo, useEffect, useState } from 'preact/compat';
import style from './style.css';
import { Image } from '../../types/types';
import { Space } from '../space/space';

interface ModalImageViewerProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    image: Image | undefined;
}

export const ModalImageViewer = memo(function ({ visible, setVisible, image }: ModalImageViewerProps) {

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [potrait, setPotrait] = useState(false);

    function handleImageLoad({ target: img }: { target: any }) {
        setDimensions({ width: img.width, height: img.height });
    }

    useEffect(() => {
        setPotrait(dimensions.height > dimensions.width);
    }, [dimensions])

    if (!image) return <></>;
    return (
        <>
            <div class={`${style.modalViewer} ${visible ? style.visible : ''}`} onClick={() => setVisible(false)}>
                <div class={`${style.imageContainer}`}>
                    <div class={style.imageFitter}>
                        <div class={style.topBar}>
                            <i class="fa-solid fa-xmark" onClick={() => setVisible(false)} />
                        </div>
                        <Space size='small' />
                        <img
                            src={`/assets/${image.source}`}
                            onLoad={handleImageLoad}
                            style={potrait ? { maxHeight: '80vh' } : { maxWidth: '50vw' }}
                        />
                        <Space size='small' />
                        <span class={`${style.caption} label-large`} dangerouslySetInnerHTML={{ __html: image.caption }} />
                    </div>
                </div>
            </div>
        </>
    );
});