/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from "preact";
import { memo, useEffect, useState } from "preact/compat";
import style from './style.css';
import { LegSharkLogo, Logo } from "../logo/logos";
import { useScrollTo } from "../../context/scroll-to-context";
import { ContentLayout } from "../layouts/content-layout/content-layout";

export const NavBar = memo(function () {
    const { scrollTo } = useScrollTo();
    const [isTop, setIsTop] = useState(true);
    const [pastPage, setPastPage] = useState(false);

    function isScrollTop(): boolean { return window.scrollY > 0; }
    function isPastPage(): boolean { return window.scrollY >= window.innerHeight; }
    function handleScroll(e: any) {
        setIsTop(isScrollTop());
        setPastPage(isPastPage());
    }
    useEffect(() => {
        setIsTop(isScrollTop());
        setPastPage(isPastPage());
        window.addEventListener('scroll', handleScroll);
        return (() => {
            window.removeEventListener('scroll', handleScroll);
        });
    }, []);

    return (
        <>
            <div class={style.fixed}  >
                <ContentLayout>
                    <div class={style.navBar} style={{ minHeight: 32 }}>
                        <div
                            onClick={() => scrollTo('top', false)}
                            class={style.logo}
                            style={{
                                opacity: !pastPage ? 0 : 1,
                                visibility: !pastPage ? 'hidden' : 'visible'
                            }}
                        >
                            <Logo color="var(--text-color-lighter-dark)" size={32} />
                        </div>
                        <Menu />
                    </div>
                </ContentLayout>
            </div>
        </>
    )
})

export const Menu = memo(function () {
    const { scrollTo } = useScrollTo();

    return (
        <>
            <div class={style.menu}>
                <Link text="Projects" onClick={() => scrollTo('projects', true)} />
                <Link text="About" onClick={() => scrollTo('about', true)} />
                <a href="https://leg-shark-studio.vercel.app">
                    <LegSharkLogo color="var(--text-color-lighter-dark)" size={32} />
                </a>
            </div>
        </>
    )
})


export const Link = memo(function ({ text, onClick }: { text: string, onClick?: () => void }) {
    return (
        <>
            <div class={`${style.link} body-large`} onClick={onClick}>
                {text}
            </div>
        </>
    )
})