import { h } from "preact";
import { memo } from 'preact/compat';
import { useRef } from "preact/hooks";
import { useBackgroundPosition, useVisible } from "../../hooks";
import { LazyImage } from "../lazy-img/lazy-image";
import { Logo } from "../logo/logos";
import { Space } from "../space/space";
import style from './style.css';
import { FALink } from "../links/fa-link";
import { IconLink } from "../links/icon-link";
import { Skills } from "../front-page/skills";

export const About = memo(function () {
    const aboutRef = useRef<any>();

    useVisible(() =>
        aboutRef.current.classList.add(style.visible), () =>
        aboutRef.current.classList.remove(style.visible));

    const backPos = useBackgroundPosition(100, -100, -200, -800);

    return (
        <div class={style.about} ref={aboutRef}>
            <div class={style.container}>
                <div
                    class={`${style.header} display-large`}
                    style={{ backgroundPosition: `${backPos.x} ${backPos.y}` }}>
                    <span>{`About me.`}</span>
                </div>
                <Space size={"large"} />
                <div class={`${style.description} body-large`} >
                    <div>
                        <div class={style.info}>
                            <div class={style.myPhoto}>
                                <LazyImage src="/assets/me.webp" placeholder={{ width: '300px', height: '300px', color: '#e3e3e3' }} />
                            </div>
                            <div class={`${style.links}`}>
                                <FALink
                                    icon={"fa-brands fa-github"}
                                    source={"https://www.github.com/jemmec"}
                                />
                                <FALink
                                    icon={"fa-brands fa-linkedin-in"}
                                    source={"https://www.linkedin.com/in/jeremywmcdade/"}
                                />
                                <IconLink
                                    width={30}
                                    height={32}
                                    source={'https://scholar.google.com.au/citations?user=59zEHt8AAAAJ'}
                                    image={'assets/icons/google-scholar.svg'}
                                />
                                <FALink
                                    icon={"fa-brands fa-soundcloud"}
                                    source={"https://www.soundcloud.com/jemmec"}
                                />
                                <FALink
                                    icon={"fa-brands fa-youtube"}
                                    source={"https://www.youtube.com/channel/UCYUsgbFpSOegfn17nvwk3Bg"}
                                />
                            </div>
                        </div>
                        <div class={style.content}>
                            <div>
                                <i>Hello!</i> I'm Jeremy McDade, a <b>software engineer</b> currently working as a Research Associate at the <a href='https://www.unisa.edu.au/research/ive/'>Australian Research Centre for Interactive Virtual Environments</a> <i>(IVE)</i> at the University of South Australia. Over the past 5 years working at IVE, I have had the opportunity to work on a variety of different research projects utilizing a broad number of software and hardware technologies. I am currently a member of the <a href='https://wearables.unisa.edu.au/'>Wearable Computer Lab</a> here at IVE, where I have been able to develop and grow my skills as a competent software engineer, tackling a range of complex problems on a daily basis. I have a strong passion for using innovative future technologies to solve the world's problems.
                            </div>
                            <div>
                                I have worked with various <b>industry partners</b> including but not limited to, the <a href='https://www.dst.defence.gov.au/'>Defence Science and Technology Group</a>, <a href='https://www.esri.com/en-us/home'>ESRI</a>, <a href='https://genixventures.com/'>Genix Ventures</a>, <a href='https://www.unisa.edu.au/about-unisa/academic-units/allied-health-and-human-performance/'>UniSA Allied Health & Human Performance</a>, the <a href='http://empathiccomputing.org/'>Empathic Computing Lab</a>, and <a href='https://www.saab.com/markets/australia'>SAAB Australia</a>. I have built <a href="/Narrative%20Visualisation">narrative visualisation software suites</a> mixing game engines and web frameworks, created the hardware and software to <a href="/Projector%20Calibration">calibrate projectors</a> for use in spatial augmented reality systems, and developed a <a href="/Virtual%20Reality%20Bike">virtual reality bicycle application</a> for use in clinical health trials.
                            </div>
                            <div>
                                Outside of my day job I enjoy producing music, drawing, working on my car, playing sports, and camping in the middle of nowhere in the Australian outback.
                            </div>
                            <div>
                                <Space size="small" />
                                <div class={`${style.links} headline-small`}>My Skills:</div>
                                <Space size="small" />
                                <Skills />
                            </div>
                        </div>
                    </div>
                    <Space size={"huge"} />
                    <div class={style.logo}>
                        <Logo color="#f0f0f0" size={150} />
                    </div>
                </div>
            </div>
        </div>
    )
});