import { createContext, h } from 'preact';
import { useContext } from "preact/hooks";

export const ScrollToContext = createContext<{
    scrollTo: (name: string, smooth?: boolean) => void
}>({
    scrollTo: () => { }
});

export function useScrollTo() {
    return useContext(ScrollToContext);
}

/**
 * An a reference to a HTMLElement
 */
export type Scrollable = {
    name: string,
    onScroll: (smooth?: boolean) => void
};

export function ScrollToContextProvider({ children, scrollables }: {
    children: JSX.Element | JSX.Element[],
    scrollables: Scrollable[]
}) {

    function handleScrollTo(name: string, smooth?: boolean) {
        if (name === 'top') {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: smooth ? 'smooth' : 'auto'
            });
        }
        const item = scrollables.find(x => x.name === name);
        if (item && item.onScroll) {
            item.onScroll(smooth);
        }
    }

    return <ScrollToContext.Provider value={{ scrollTo: handleScrollTo }}>
        {children}
    </ScrollToContext.Provider>
}