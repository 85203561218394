import { h } from 'preact';
import { memo } from 'preact/compat';
import { FooterLinks } from '../links/footer-links';
import { Space } from '../space/space';
import style from './style.css';
import { useScrollTo } from '../../context/scroll-to-context';

export const Footer = memo(function () {
    const { scrollTo } = useScrollTo();
    const year = new Date().getFullYear();
    return (
        <div class={`${style.footer} label-small`}>
            <div class={`${style.container}`}>
                <div>
                    {/* <FooterLinks onTop={()=>scrollTo('top',true)} /> */}
                    {/* <Space size={"small"} /> */}
                    <p>Built by me ©{year}</p>
                </div>
                <div class={style.divider} />
                <div class={`${style.info} `}>
                    <p>
                        If you would like to get in touch, message me on <a href="https://www.linkedin.com/in/jeremywmcdade/">LinkedIn</a>.
                    </p>
                    <p>
                        This website was built with the <a href='https://preactjs.com/'>preact</a> framework.
                    </p>
                </div>
            </div>
            <Space size={"large"} />
        </div>
    );
});

