import { h } from 'preact';
import { memo } from 'preact/compat';
import style from './style.css';
import { Link } from 'preact-router';

export const IconLink = memo(function (props: any) {
    return (
        <Link href={props.source}>
            <div class={style.iconLink}>
                <div class={style.icon} style={{
                    width: props.width,
                    height: props.height,
                    WebkitMaskImage: `url(${props.image})`,
                    maskImage: `url(${props.image})`,
                    maskSize: `${props.height}px`,
                    webkitMaskSize: `${props.height}px`,
                    maskPosition: 'center',
                    webkitMaskPosition: '2px -2px',
                }} />
            </div>
        </Link>
    );
})
