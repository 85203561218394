import { h } from 'preact';
import { memo, useState } from 'preact/compat';
import Skeleton from '../skeleton/skeleton';
import style from './style.css';

export const Skills = memo(function () {
    return (
        <div
            class={style.skills}>
            <div class={style.skillContainer}>
                <Skill src="/assets/brands/unity.svg" name="Unity" />
                <Skill src="/assets/brands/csharp.svg" name="C#" />
                <Skill src="/assets/brands/typescript.svg" name="Typescript" />
                <Skill src="/assets/brands/javascript.svg" name="Javascript" />
                <Skill src="/assets/brands/xamarin.svg" name="Xamarin" />
                <Skill src="/assets/brands/react.svg" name="React" />
                <Skill src="/assets/brands/java.svg" name="Java" />
                <Skill src="/assets/brands/flutter.svg" name="Flutter" />
                <Skill src="/assets/brands/cplusplus.svg" name="C++" />
                <Skill src="/assets/brands/python.svg" name="Python" />
            </div>
        </div>
    );
});

const Skill = memo(function ({ src, name }: { src: string, name: string }) {
    const [loaded, setLoaded] = useState<boolean>(false);
    const size = 32;
    return (
        <div>
            <div class={`${style.logo} body-large`}>
                <img onLoad={() => setLoaded(true)} src={src} alt={name} width={size} height={size} />
                <div class={style.name}>
                    {name}
                </div>
            </div>
        </div>
    )
});
