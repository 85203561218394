/* eslint-disable react/no-danger */
import { h } from "preact";
import { useRef, useEffect } from 'preact/hooks';
import { memo } from 'preact/compat';
import { Project } from "../../types/types";
import { Space } from "../space/space";
import style from './style.css';
import { useVisible } from "../../hooks";

interface ProjectItemProps {
    project: Project;
    enabled: boolean;
}

export const ProjectItem = memo(function ({ project, enabled }: ProjectItemProps) {
    const projectRef: any = useRef();
    const assetsPath = "/assets/";

    let tags = null;
    if (project.tags && project.tags.length > 0) {
        tags = <div class={`${style.tags} label-small`}>
            {
                project.tags.map((tag, index) => {
                    return <div key={index} className='tag'>
                        {`#${tag}`}
                    </div>
                })
            }
        </div>
    }

    let hero = null;
    if (project.hero && project.hero !== "") {
        hero = <div class={style.hero} style={`background-image: url(${assetsPath + project.hero});`} />
    }

    let tools = null;
    if (project.tools && project.tools.length > 0) {
        tools =
            <div class={style.tools}>
                {
                    project.tools.map((tool: string) =>
                        <div key={tool} class={style.logo}>
                            <img src={assetsPath + tool} />
                        </div>
                    )
                }
            </div>
    }

    useVisible(() =>
        projectRef.current.classList.add(style.visible), () =>
        projectRef.current.classList.remove(style.visible))

    return (
        <div
            ref={projectRef}
            class={`${style.project} ${enabled ? '' : style.disabled}`}
            style={hero ? `grid-row-end: span 2;` : ""}
        >
            {hero}
            <div class={style.content}>
                {tools}
                {tags}
                <Space size={'tiny'} />
                <div class={`${style.title} headline-large`}>
                    {project.title}
                </div>
                <Space size={'tiny'} />
                <div
                    class={`${style.caption}  body-large`}
                    dangerouslySetInnerHTML={{ __html: project.caption }}
                />
            </div>
        </div>
    )
});