import { h } from 'preact';
import { memo } from 'preact/compat';
import { useState, useEffect, useRef } from 'preact/hooks';
import Skeleton from '../skeleton/skeleton';
import style from './style.css';

type LazyImageProps = {
    src: string;
    onClick?: () => void;
    placeholder?: {
        width?: string,
        height?: string,
        color?: string,
    };
}

export const LazyImage = memo(function ({ src, onClick, placeholder }: LazyImageProps) {
    const imgElement: any = useRef();
    const onePixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    const [dataSrc, setDataSrc] = useState('');
    const [loaded, setLoaded] = useState<boolean>(false);
    const handleLoading = (event: any) => {
        if (event.target.complete) {
            setLoaded(true);
            event.target.removeEventListener('load', handleLoading);
        }
    }
    function handleInview(entries: any, observer: any) {
        entries.forEach((entry: any) => {
            if (entry.intersectionRatio) {
                entry.target.addEventListener('load', handleLoading);
                entry.target.src = entry.target.getAttribute("data-src");
                observer.unobserve(entry.target);
            }
        })
    };
    useEffect(() => {
        const observer = new IntersectionObserver(handleInview);
        if (imgElement.current)
            observer.observe(imgElement.current);
    }, []);

    useEffect(() => {
        if (!loaded) {
            setDataSrc(src);
        }
        else {
            //TODO: Implement re-observe if the source changes
            imgElement.current.src = src;
        }
    }, [src, loaded]);

    const loaderInfo = { width: '180px', height: '100px', color: '#cdcdcd' };
    if (placeholder) {
        if (placeholder.width)
            loaderInfo.width = placeholder.width;
        if (placeholder.height)
            loaderInfo.height = placeholder.height;
        if (placeholder.color)
            loaderInfo.color = placeholder.color;
    }

    return (
        <div class={style.imageContainer} onClick={onClick}>
            <div
                class={style.loader}
                style={`display: ${loaded ? 'none' : 'block'};`}
            >
                <Skeleton width={loaderInfo.width} height={loaderInfo.height} />
            </div>
            <img
                style={{ height: loaded ? loaderInfo.height : 0 }}
                ref={imgElement}
                src={onePixel}
                data-src={dataSrc}
            />
        </div>
    )
})
