/* eslint-disable react/no-danger */
/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from 'preact';
import { memo } from 'preact/compat';
import { useMarkdown } from '../../hooks/use-markdown';
import style from './style.css';
import { marked } from 'marked';
import Skeleton from '../skeleton/skeleton';
import { Space } from '../space/space';

type MarkdownProps = {
    source: string;
}

export const Markdown = memo(function ({ source }: MarkdownProps) {
    const { markdown, loading, error } = useMarkdown(source);
    if (loading) return (
        <>
            <Skeleton height={34} width={"30%"} />
            <Space size='small' />
            <Skeleton count={6} />
            <Space size='medium' />
        </>
    )
    if (error || !markdown) return <></>;
    return (
        <>
            <div
                class={`${style.markdown} body-large`}
                dangerouslySetInnerHTML={{ __html: marked(markdown, { smartypants: true }) }}
            />
            <Space size='large' />
        </>
    );
});