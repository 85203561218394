import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import style from './style.css';
import { memo } from 'preact/compat';
import { useScrollTo } from '../../context/scroll-to-context';

type BackToTopProps = {
    visible: boolean;
}

export const BackToTop = memo(function ({ visible }: BackToTopProps) {
    const { scrollTo } = useScrollTo();
    const buttonRef = useRef<any>();
    useEffect(() => {
        if (visible)
            buttonRef.current.classList.add(style.visible);
        else
            buttonRef.current.classList.remove(style.visible);
    }, [visible])

    return (
        <div class={`${style.backToTop} r-parent`}>
            <div class={`${style.relative} r-container`}>
                <div
                    ref={buttonRef}
                    class={`${style.button} label-large`}
                    onClick={() => scrollTo('top', true)}
                >
                    <i class="fa-solid fa-caret-up" />
                    <div>
                        To Top
                    </div>
                </div>
            </div>
        </div>
    );
});

