import { useEffect, useState } from 'preact/hooks';
export const useBackgroundPosition = (minX: number, maxX: number, minY: number, maxY: number) => {
    const [state, setState] = useState<{ x: string, y: string }>({ x: 'center', y: 'center' });
    const getRandomNumber = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    useEffect(() => {
        setState({
            x: `${getRandomNumber(minX, maxX)}px`,
            y: `${getRandomNumber(minY, maxY)}px`
        });
    }, [maxX, maxY, minX, minY])
    return state;
};